import { CertificateType } from '@dltru/dfa-models'
import { Box, Button, MiniModal, QuestionCircle } from '@dltru/dfa-ui'
import { FC, useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'

import { emissionDevDfa, emissionDevDfaPrefly } from '@store/dfa/details'

import { CertificateListModalWithTransactionData } from '@components/Modals/Certificate/CertificateListModalWithTransactionData'

export interface ISetEmitmentModal {
    isModalVisible: boolean
    setIsModalVisible: (val: boolean) => void
    dfaId?: number
}

export const SetEmitmentModal: FC<ISetEmitmentModal> = ({
    isModalVisible,
    setIsModalVisible,
    dfaId,
}) => {
    const dispatch = useDispatch()
    const [isVisibleCertificateModal, setIsVisibleCertificateModal] = useState(false)

    const handleOk = useCallback((cert: CertificateType) => {
        dispatch(emissionDevDfaPrefly({ asset_id: dfaId, skid: cert.skid }))
    }, [dfaId])

    const handleSign = useCallback((cert: CertificateType) => {
        dispatch(emissionDevDfa({ asset_id: dfaId, skid: cert.skid }))
        setIsModalVisible(false)
    },[dfaId])


    const handleCancel = () => {
        setIsModalVisible(false)
    }

    return (
        <>
            <MiniModal visible={isModalVisible} onCancel={handleCancel}>
                <div>
                    <Box direction="row" align="center">
                        <Box className="modal-content-shift">
                            <QuestionCircle className="modal-icon modal-question-icon" />
                        </Box>
                        <div className="ant-modal-title">Осуществить выпуск ЦФА</div>
                    </Box>
                    <Box direction="row" align="center" justify="right">
                        <div className="modal-content-shift">&nbsp;</div>
                        <p className="modal-content-text">
                            Вы действительно хотите осуществить выпуск ЦФА?
                        </p>
                    </Box>
                    <Box
                        className="ant-modal-footer ant-modal-footer-no-border"
                        padding={[0, 8, 8, 8]}
                        direction="row"
                        justify="right"
                    >
                        <Button borderRadius={12} onClick={handleCancel}>
                            Отмена
                        </Button>
                        {dfaId && (
                            <Button
                                borderRadius={12}
                                type="primary"
                                onClick={() => {
                                    setIsVisibleCertificateModal(true)
                                }}
                            >
                                Да, выпустить
                            </Button>
                        )}
                    </Box>
                </div>
            </MiniModal>
            <CertificateListModalWithTransactionData
                isModalVisible={isVisibleCertificateModal}
                setIsModalVisible={setIsVisibleCertificateModal}
                onSubscribe={handleOk}
                onSign={handleSign}
            />
        </>
    )
}

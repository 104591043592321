import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
    CertificateType,
    CollectType,
    PriceSource,
    SettlementsType,
    getBasePercent,
    getPennies,
} from '@dltru/dfa-models'
import {
    Alert,
    Amount,
    Box,
    Button,
    Divider,
    Modal,
    Price,
    QuestionCircle,
    LabelRow as Row,
    Space,
} from '@dltru/dfa-ui'

// import { authSelector } from '@store/auth/selectors'
import { startRepayment, startRepaymentPrefly } from '@store/dfa/details'
import { dfaDetailsSelector } from '@store/dfa/details/selectors'
import { moneySelector } from '@store/money/selectors'

import api from '@services/api'

import { WalletWidget } from '@components/WalletWidget'

import { AgreementLink } from '../AgreementLink/AgreementLink'

import style from './RepaymentModal.m.less'
import { agreementText, agreementTextDirect } from './constants'
import { CertificateListModalWithTransactionData } from '@components/Modals/Certificate/CertificateListModalWithTransactionData'

export interface IInitRepaymentModal {
    isModalVisible: boolean
    onCancel: () => void
    redeemFlexPrice?: number
}

export const RepaymentModal: FC<IInitRepaymentModal> = ({
    isModalVisible,
    onCancel,
    redeemFlexPrice,
}) => {
    const account = useSelector(moneySelector.selectAccount)
    const dfa = useSelector(dfaDetailsSelector.selectDfaDetails)
    const isDirect = dfa.repayment_settlements_type === SettlementsType.direct
    // const user_id = useSelector(authSelector.selectUserUid)

    const reduxDispatch = useDispatch()

    const redeemPricePerDfa = redeemFlexPrice ?? dfa?.redeem_price_per_dfa ?? 0
    const repaymentSum = redeemPricePerDfa * (dfa.total_supply_invested ?? 0)
    const [isBalanceError, setIsBalanceError] = useState(false)
    const [isVisibleCertificateModal, setIsVisibleCertificateModal] = useState(false)
    const [fee, setFee] = useState(0)
    const [fee017, setFee017] = useState(0)
    const [repaymentWithCommissionSum, setRepaymentWithCommissionSum] = useState(0)

    useEffect(() => {
        const calcCommission = async () => {
            if (!dfa.id) {
                return
            }

            const isDynamicPrice = dfa.price_source_type === PriceSource.dynamic
            const feeFromApi = await api.lib.calculateRepaymentFeeService({
                asset_id: dfa.id,
                redeem_price_per_dfa: getPennies(redeemPricePerDfa),
                repayment_collect_type: dfa.repayment_collect_type || 'none',
                redemption_spread: isDynamicPrice
                    ? getBasePercent(dfa.redemption_spread)
                    : undefined,
            })
            const feeNumber = feeFromApi?.data?.item?.fee_amount ?? 0
            const fee017Number = feeFromApi?.data?.item?.payment_order_fee_amount ?? 0

            setFee(feeNumber)
            setFee017(fee017Number)
        }

        if (isModalVisible) {
            calcCommission()
        }
    }, [isModalVisible, dfa?.id, redeemPricePerDfa])

    useEffect(() => {
        const _repaymentWithCommissionSum = repaymentSum * 100 + (fee ?? 0) + (fee017 ?? 0)
        setRepaymentWithCommissionSum(_repaymentWithCommissionSum / 100)

        const balanceError =
            (isDirect ? fee : _repaymentWithCommissionSum) > (account?.balance?.available as number)
        setIsBalanceError(balanceError)
    }, [repaymentSum, fee, account, fee017])

    const onSubscribe = async (cert: CertificateType) => {
        if (!dfa.id) {
            return
        }

        reduxDispatch(
            startRepaymentPrefly({
                asset_id: dfa.id,
                skid: cert.skid,
                repayment_fee: fee + fee017,
                redeem_price_per_dfa: getPennies(redeemPricePerDfa),
                redemption_spread: getBasePercent(dfa.redemption_spread),
            }),
        )
        
    }

    const onSign = (cert: CertificateType) => {
        if (!dfa.id) {
            return
        }
        
        reduxDispatch(
            startRepayment({
                asset_id: dfa.id,
                skid: cert.skid,
                repayment_fee: fee + fee017,
                redeem_price_per_dfa: getPennies(redeemPricePerDfa),
                redemption_spread: getBasePercent(dfa.redemption_spread),
            }),
        )
        onCancel()
    }

    return (
        <>
            <Modal
                visible={isModalVisible}
                onCancel={onCancel}
                width={600}
                closable={false}
                title={
                    <Space size={0}>
                        <QuestionCircle className="modal-icon modal-question-icon" />
                        Погасить выпуск ЦФА
                    </Space>
                }
            >
                <div className={style.initRepaymentModal}>
                    <Box direction="column">
                        <Row label="Тикер">
                            <strong>{dfa.ticker_symbol}</strong>
                        </Row>
                        <Divider margin={[10, 0]} />
                        <Row label="Цена погашения ЦФА">
                            <Price price={redeemPricePerDfa} />
                        </Row>
                        <Divider margin={[10, 0]} />
                        <Row label="Количество, ед.">
                            <Amount value={dfa.total_supply_invested ?? 0} />
                        </Row>
                        <Divider margin={[10, 0]} />
                        <Row label="Стоимость погашаемых активов">
                            <Price price={repaymentSum} />
                        </Row>
                        <Divider margin={[10, 0]} />
                        <Row label="Сумма комиссии за погашение ЦФА">
                            <Price price={(fee || 0) / 100} />
                        </Row>
                        {dfa.repayment_collect_type === CollectType.bank_account && (
                            <Row label="Сумма комиссии за платежное поручение">
                                <Price price={(fee017 || 0) / 100} />
                            </Row>
                        )}
                        {!isDirect && (
                            <>
                                <Divider margin={[10, 0]} />
                                <Row label="Итого">
                                    <Price price={repaymentWithCommissionSum} />
                                </Row>
                            </>
                        )}
                        <WalletWidget
                            balance={account?.balance?.available}
                            account={String(account?.number)}
                            error={isBalanceError}
                        />
                        {isDirect && (
                            <Alert
                                showIcon
                                type="info"
                                description="Сумма комиссии будет списана с вашего лицевого счёта.  Стоимость погашаемых ЦФА необходимо перечислить владельцам вне системы."
                            />
                        )}

                        <Box direction="row" justify="center">
                            <AgreementLink text={isDirect ? agreementTextDirect : agreementText} />
                        </Box>
                    </Box>
                </div>
                <div className={style.repaymentModalFooter}>
                    <Button borderRadius={12} onClick={onCancel}>
                        Отмена
                    </Button>
                    <Button
                        borderRadius={12}
                        type="primary"
                        onClick={() => setIsVisibleCertificateModal(true)}
                        disabled={isBalanceError}
                    >
                        Да, погасить ЦФА
                    </Button>
                </div>
            </Modal>

            <CertificateListModalWithTransactionData
                isModalVisible={isVisibleCertificateModal}
                setIsModalVisible={setIsVisibleCertificateModal}
                onSubscribe={onSubscribe}
                onSign={onSign}
            />
        </>
    )
}

import React from 'react'

export const agreementText = (
    <>
        <p>
            Настоящим Эмитент направляет предусмотренное подпунктом (i) пункта 13.3.1 Правил
            Поручение о перечислении на Банковские счета Владельцев или Поручение о перечислении на
            Счета аналитического учета Владельцев (в зависимости от способа осуществления расчетов
            предусмотренного Решением о выпуске ЦФА), которые будут определены в Дату исполнения в
            соответствии с пунктом 13.3.2 Правил, в целях исполнения обязательств, удостоверенных
            ЦФА с Идентификационным номером выпуска ЦФА, указанным в графе «Тикер» формы «Погасить
            выпуск ЦФА», на сумму таких обязательств, указанную в графе «Итого» формы «Погасить
            выпуск ЦФА».
        </p>
        <p>
            Указанное выше поручение считается направленным с момента нажатия кнопки «Да, погасить
            ЦФА».
        </p>
        <p>
            Все термины, встречающиеся в настоящем тексте с заглавной буквы, применяются в значении,
            предусмотренном правилами информационной системы Общества с ограниченной
            ответственностью «Системы распределенного реестра».
        </p>
    </>
)

export const agreementTextDirect = (
    <>
        <p>
            Настоящим Эмитент, в соответствии с пунктом 13.4 Правил, направляет указание на
            внесение в Реестр ЦФА записи о погашении ЦФА с Идентификационным номером выпуска ЦФА,
            указанным в графе «Тикер» формы «Погасить выпуск ЦФА». Указание на внесение в Реестр ЦФА
            записи о погашении ЦФА считается направленным с момента нажатия кнопки «Да, погасить
            ЦФА».
        </p>
        <p>
            Все термины, встречающиеся в настоящем тексте с заглавной буквы, применяются в значении,
            предусмотренном правилами информационной системы Общества с ограниченной
            ответственностью «Системы распределенного реестра».
        </p>
    </>
)

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".makeIssueFailedButton_zgHY_ {\n  background-color: #ff4d4f !important;\n  border: 1px solid #ff4d4f !important;\n}\n.makeIssueFailedButton_zgHY_:hover {\n  background-color: #ffa39e !important;\n  border: 1px solid #ffa39e !important;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/DFAEmission/DFAEmissionCardPage/components/Footer/Modals/FailedIssueModal/failedIssueModal.m.less"],"names":[],"mappings":"AAEA;EACI,oCAAA;EACA,oCAAA;AADJ;AAGI;EACI,oCAAA;EACA,oCAAA;AADR","sourcesContent":["@import 'style/palette';\n\n.makeIssueFailedButton {\n    background-color: @red-5 !important;\n    border: 1px solid @red-5 !important;\n\n    &:hover {\n        background-color: @red-3 !important;\n        border: 1px solid @red-3 !important;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"makeIssueFailedButton": "makeIssueFailedButton_zgHY_"
};
export default ___CSS_LOADER_EXPORT___;

import { Box, Button, Divider, Form, Modal, WrappedInput } from '@dltru/dfa-ui'
import { FC } from 'react'
import { useDispatch } from 'react-redux'

import { makeIssueFailed } from '@store/dfa/details'

import { UploadDocumentContainerWrapper } from '@components/Form/components/wrappers'

import style from './failedIssueModal.m.less'

export interface IFailedIssueModal {
    isModalVisible: boolean
    setIsModalVisible: (val: boolean) => void
    dfaId?: number
}

const FailedIssueModal: FC<IFailedIssueModal> = ({ isModalVisible, setIsModalVisible, dfaId }) => {
    const [form] = Form.useForm()
    const reduxDispatch = useDispatch()

    const closeModal = () => {
        setIsModalVisible(false)
    }

    const getDocumentsUids = (docs) => docs.map((doc) => doc.uid)

    return (
        <Modal
            title="Признание выпуска несостоявшимся"
            visible={isModalVisible}
            onOk={closeModal}
            onCancel={closeModal}
            width={522}
            footer={[
                <Button borderRadius={12} onClick={closeModal}>
                    Отмена
                </Button>,
                <Button
                    borderRadius={12}
                    type="primary"
                    className={style.makeIssueFailedButton}
                    onClick={() => {
                        reduxDispatch(
                            makeIssueFailed({
                                dfaId: Number(dfaId),
                                reject_reason: form.getFieldValue('approval_document_comment'),
                                reject_reason_documents_uuid: getDocumentsUids(
                                    form.getFieldValue('approval_document_file_uuid'),
                                ),
                            }),
                        )
                        setIsModalVisible(false)
                    }}
                >
                    Признать выпуск несостоявшимся
                </Button>,
            ]}
        >
            <div>
                <Box>
                    <p>
                        Признание выпуска ЦФА несостоявшимся влечет следующие последствия:
                        <ul className="list-without-shift">
                            <li>заявка на выпуск ЦФА снимается с Витрины предложений,</li>
                            <li>
                                информация о признании выпуска ЦФА несостоявшимся размещается на
                                Сайте Оператора,
                            </li>
                            <li>
                                все обязательства по заключенным Сделкам размещения прекращаются.
                            </li>
                        </ul>
                    </p>
                </Box>
                <Divider margin={0} />
                <Box padding={[24, 0, 16, 0]}>
                    <h3 className="page-content-header">
                        Причина признания выпуска ЦФА несостоявшимся
                    </h3>
                </Box>
                <Form
                    form={form}
                    layout="vertical"
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    onFinish={() => {}}
                >
                    <Box direction="column" margin={[0, 0, 0, 0]}>
                        <UploadDocumentContainerWrapper
                            name="approval_document_file_uuid"
                            title="Загрузите фотографии или сканы документов.  Допустимый формат файлов JPG, JPEG, TIFF, PDF, PNG. Общий вес не более 25 МБ"
                            required
                            maxSizeMb={5}
                            accept=".jpg, .jpeg, .tiff, .pdf, .png"
                        >
                            <WrappedInput
                                className="formComponentItem"
                                placeholder="Ваш комментарий"
                                name="approval_document_comment"
                                multiline
                                required
                                maxLength={200}
                            />
                        </UploadDocumentContainerWrapper>
                    </Box>
                </Form>
            </div>
        </Modal>
    )
}

export default FailedIssueModal

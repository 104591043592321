// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".initRepaymentModal_SYJnZ {\n  display: flex;\n  flex-direction: column;\n  padding: 0 40px;\n}\n.repaymentModalFooter_LMZqW {\n  display: flex;\n  justify-content: flex-end;\n}\n.repaymentModalFooter_LMZqW button:first-child {\n  margin-right: 8px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/DFAEmission/DFAEmissionCardPage/components/Footer/Modals/RepaymentModal/RepaymentModal.m.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,eAAA;AACJ;AAEA;EACI,aAAA;EACA,yBAAA;AAAJ;AAFA;EAKQ,iBAAA;AAAR","sourcesContent":[".initRepaymentModal {\n    display: flex;\n    flex-direction: column;\n    padding: 0 40px;\n}\n\n.repaymentModalFooter {\n    display: flex;\n    justify-content: flex-end;\n\n    button:first-child {\n        margin-right: 8px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"initRepaymentModal": "initRepaymentModal_SYJnZ",
	"repaymentModalFooter": "repaymentModalFooter_LMZqW"
};
export default ___CSS_LOADER_EXPORT___;

import { FC, useLayoutEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { FieldData } from 'rc-field-form/lib/interface'

import { stringToNumber } from '@dltru/dfa-models'
import {
    Box,
    Button,
    CurrencyInput,
    Divider,
    Form,
    MiniModal,
    commonValidators,
} from '@dltru/dfa-ui'

import { dfaDetailsSelector } from '@store/dfa/details/selectors'

import { RepaymentModal } from './RepaymentModal/RepaymentModal'


interface ISetRepaymentPriceProps {
    isModalVisible: boolean
    setIsModalVisible: (val: boolean) => void
}

type FormValueType = {
    flex_price: string
}

export const SetRepaymentPrice: FC<ISetRepaymentPriceProps> = ({
    isModalVisible,
    setIsModalVisible,
}) => {
    const dfa = useSelector(dfaDetailsSelector.selectDfaDetails)
    const [form] = Form.useForm<FormValueType>()
    const [redeemFlexPrice, setRedeemFlexPrice] = useState<number>()
    const [isDisableBtn, setIsDisableBtn] = useState(true)
    const [isVisibleInitModal, setIsVisibleInitModal] = useState(false)

    useLayoutEffect(() => {
        if (isModalVisible) {
            setIsDisableBtn(true)
            setRedeemFlexPrice(undefined)
            form.resetFields()
        }
    }, [isModalVisible])

    const handleCancel = () => {
        setIsModalVisible(false)
    }
    const handleOk = () => {
        setIsVisibleInitModal(true)
    }

    const onFieldsChange = (fields: FieldData[]) => {
        setIsDisableBtn(fields.some(({ errors, value }) => errors?.length || !value))
        setRedeemFlexPrice(stringToNumber(fields[0]?.value ?? ''))
    }

    return (
        <>
            <MiniModal
                visible={isModalVisible}
                onCancel={handleCancel}
                title="Цена погашения выпуска"
                closable
                width={600}
                footer={[
                    <Button borderRadius={12} onClick={handleCancel}>
                        Отмена
                    </Button>,
                    <Button
                        borderRadius={12}
                        type="primary"
                        onClick={form.submit}
                        disabled={isDisableBtn}
                    >
                        Далее
                    </Button>,
                ]}
            >
                <Box margin={[40, 0, 16, 0]}>
                    Для погашения ЦФА необходимо указать цену погашения за единицу ЦФА, исчисленную
                    в рублях.
                </Box>
                <Divider margin={0} />
                <Box margin={[16, 0]}>Порядок определения цены ЦФА при погашении:</Box>
                <Box margin={[16, 0]}>
                    <strong>{dfa?.redemption_description}</strong>
                </Box>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={handleOk}
                    onFieldsChange={onFieldsChange}
                >
                    <CurrencyInput
                        className="formComponentItem--md"
                        label="Цена ЦФА при погашении"
                        name="flex_price"
                        required
                        rules={[{ validator: commonValidators.positive() }]}
                        size="large"
                    />
                </Form>
            </MiniModal>
            <RepaymentModal
                isModalVisible={isVisibleInitModal}
                onCancel={() => setIsVisibleInitModal(false)}
                redeemFlexPrice={redeemFlexPrice}
            />
        </>
    )
}